import React from 'react';

import classNames from 'classnames';

import { typeAheadUri } from '../../../config/client';
import request from '../../../http/request';
import { getUrl, currentLocale, isClient } from '../../../helpers';

import Slim from './variants/Slim';
import Full from './variants/Full';
import ProLink from '../ProLink/ProLink';

class TypeAhead extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: '',
            valueMutated: false,
            results: [],
            selectedResultKey: 0,
        };
        this.handleChange = this.handleChange.bind(this);
        this.fetchResults = this.fetchResults.bind(this);
        this.returnResults = this.returnResults.bind(this);
        this.typeAheadUri = this.typeAheadUri.bind(this);
        this.resetResults = this.resetResults.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.setSelectedValue = this.setSelectedValue.bind(this);
        this.resetValue = this.resetValue.bind(this);
    }

    resetValue(props) {
        let value = '';
        let valueMutated = this.state.valueMutated;

        if (!valueMutated) {
            value = props.keyword ? props.keyword : '';
        } else {
            value = this.state.value;
        }

        this.setState({
            value,
            valueMutated,
        });
    }

    handleChange = e => {
        if (this.props.onChange) {
            this.props.onChange(e);
        }
        const value = e && e.target && e.target.value ? e.target.value.toLowerCase() : '';
        const valueMutated = true;

        if (value && value.length >= 3) {
            this.fetchResults(value);
        } else {
            this.resetResults();
        }

        this.setState({
            value: e.target.value,
            valueMutated,
            selectedResultKey: 0,
        });
    };

    handleKeyDown = e => {
        e = e || window.event;

        const { selectedResultKey, results } = this.state;
        const { limit, searchOnClick } = this.props;

        // No handling needed if no results
        if (results.length === 0) {
            return false;
        }

        const selectedResult = results[selectedResultKey];

        if (e.keyCode == '40') {
            // down key
            e.preventDefault();
            if (selectedResultKey < limit && selectedResultKey < results.length - 1) {
                this.setState({ selectedResultKey: selectedResultKey + 1 });
            }
        } else if (e.keyCode == '38') {
            // up key
            e.preventDefault();
            if (selectedResultKey > 0) {
                this.setState({ selectedResultKey: selectedResultKey - 1 });
            }
        } else if (e.keyCode == '13') {
            // enter
            if (searchOnClick && selectedResult && selectedResult.link && window) {
                e.preventDefault();
                window.location.href = selectedResult.link;
            } else if (!searchOnClick && selectedResult && selectedResult.name) {
                e.preventDefault();
                this.setSelectedValue(selectedResult.name);
            } else if (
                !searchOnClick &&
                selectedResult &&
                (typeof selectedResult === 'string' || selectedResult instanceof String)
            ) {
                e.preventDefault();
                this.setSelectedValue(selectedResult);
            }
        }
    };

    // TODO: make sure typeAheadUrl becomes typeAheadUri in backend and front-end
    typeAheadUri(searchTerm) {
        const basePath = getUrl();
        const locale = currentLocale.get();
        // const { typeAheadUri } = config;

        const { typeAheadUrl } = this.props;

        let fetchUrl = `${basePath}/${locale}/${typeAheadUri}/${searchTerm}`;
        if (typeAheadUrl) {
            // TODO: clean the backend uri from locale and trailing backslash
            fetchUrl = `${basePath}${typeAheadUrl}${searchTerm}`;
        }

        return fetchUrl;
    }

    // fetchResults(searchTerm) {
    //   const { type } = this.props;

    //   let fetchUrl = this.typeAheadUri(searchTerm);

    //   let fetch = request
    //     .get(fetchUrl)
    //     .end((err, res) => {
    //       if (err || !res.ok) {
    //         this.setState({
    //           results: []
    //         });
    //         console.warn(err);
    //       }
    //     })
    //     .then(res => {
    //       /* parse the body */

    //       const body = res.body || {};

    //       const results = type === 's' ? body.suggestions : body.results;

    //       this.returnResults(results || []);
    //     });

    //   return fetch;
    // }

    async fetchResults(searchTerm) {
        const { type } = this.props;

        const fetchUrl = this.typeAheadUri(searchTerm);

        try {
            const response = await request.get(fetchUrl);

            if (!response.ok) {
                this.setState({ results: [] });
                console.warn(response.statusType);
            } else {
                const body = response.body || {};
                const results = type === 's' ? body.suggestions : body.results;

                this.returnResults(results || []);
            }
        } catch (error) {
            this.setState({ results: [] });
            console.warn(error);
        }
    }

    returnResults(results) {
        this.setState({
            results,
        });
    }

    resetResults() {
        this.setState({
            results: [],
        });
    }

    componentDidMount() {
        this.hideTypeaheadOnClick();
        this.resetResults();
        this.resetValue(this.props);
    }

    componentWillReceiveProps(nextProps) {
        this.resetResults();
        this.resetValue(nextProps);
    }

    handleClick(e, product) {
        e.preventDefault();

        this.setSelectedValue(product);
    }

    setSelectedValue(selectedValue) {
        this.refs.searchInput.blur();
        this.setState({
            results: [],
            value: selectedValue,
        });

        if (this.props.onResultSelect) {
            this.props.onResultSelect(selectedValue);
        }
    }

    hideTypeaheadOnClick() {
        const searchOnClick = this.props.searchOnClick;

        if (isClient()) {
            document.body.addEventListener(
                'click',
                element => {
                    const result =
                        element && element.target && element.target.dataset && element.target.dataset.result
                            ? element.target.dataset.result
                            : null;

                    if (result && !searchOnClick) {
                        this.handleClick(element, result);
                    }

                    this.setState({
                        results: [],
                    });
                },
                false
            );
        }
    }

    render() {
        const { value, selectedResultKey } = this.state;

        const {
            type = '',
            cat = '',
            className,
            id,
            placeholder,
            willShowAllLink,
            name = 'default',
            autoFocus,
            searchOnClick,
            useFull,
            limit,
        } = this.props;

        const resultsClassNames = classNames({
            'typeahead-results': true,
            'has-results': this.state.results.length,
        });

        const results = this.state.results && this.state.results.length > 0 ? this.state.results.slice(0, limit) : [];

        const typeAheadVariant = useFull ? (
            <Full
                results={results}
                searchOnClick={searchOnClick}
                type={type}
                cat={cat}
                selectedResultKey={selectedResultKey}
            />
        ) : (
            <Slim
                results={results}
                searchOnClick={searchOnClick}
                type={type}
                cat={cat}
                selectedResultKey={selectedResultKey}
                supportLink={this.props.supportLink}
            />
        );

        return (
            <div className="typeahead-wrapper col-12 p-0">
                <div className="typeahead-default">
                    <label className="sr-only" htmlFor={id}>
                        {placeholder}
                    </label>
                    <input
                        autoFocus={autoFocus}
                        autoComplete="off"
                        onChange={this.handleChange}
                        onKeyDown={this.handleKeyDown}
                        placeholder={placeholder}
                        type="text"
                        className={className}
                        id={id}
                        data-testid={id}
                        name={name}
                        value={this.props.onChange ? this.props.value : value}
                        ref="searchInput"
                    />
                </div>

                <div className={resultsClassNames}>
                    {typeAheadVariant}

                    {willShowAllLink && (
                        <ProLink
                            className="show-all-results"
                            bypassSpa={true}
                            to={`${this.props.showAllResultsLink}${this.state.value}`}
                        >
                            {this.props.showAllResults}
                        </ProLink>
                    )}
                </div>
            </div>
        );
    }
}

TypeAhead.defaultProps = {
    limit: 5,
};

export default TypeAhead;
