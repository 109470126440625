import React, { useRef, useEffect, useCallback } from 'react';
import Player from '@vimeo/player';

const VimeoPlayer = ({ vimeoSourceUrl, logicalName }) => {
    const iframeRef = useRef(null);
    const playerRef = useRef(null);
    const halfWatchedRef = useRef(false);
    const videoCompletedRef = useRef(false);

    const pushToDataLayer = useCallback((event, data) => {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({ event, ...data });
    }, []);

    const createEventData = useCallback(
        (data, videoName, videoId) => ({
            ...data,
            video_name: videoName,
            video_id: videoId,
            media_source: vimeoSourceUrl,
            module_id: logicalName,
            videoPlayer: playerRef.current,
        }),
        [vimeoSourceUrl]
    );

    const handlePlay = useCallback(
        (data, videoName, videoId) => {
            const eventData = createEventData(data, logicalName, videoName, videoId);
            pushToDataLayer('video_play', eventData);
            videoCompletedRef.current = false;
        },
        [createEventData, pushToDataLayer]
    );

    const handlePause = useCallback(
        async (data, videoName, videoId) => {
            try {
                const player = playerRef.current;
                const duration = await player.getDuration();
                const currentTime = await player.getCurrentTime();
                const bufferTime = 0.1;

                if (!videoCompletedRef.current && duration - currentTime > bufferTime) {
                    const eventData = createEventData(data, videoName, videoId);
                    pushToDataLayer('video_pause', eventData);
                }
            } catch (error) {
                console.error('Error handling pause event:', error);
            }
        },
        [createEventData, pushToDataLayer]
    );

    const handleEnded = useCallback(
        (data, videoName, videoId) => {
            const eventData = createEventData(data, videoName, videoId);
            pushToDataLayer('video_completed', eventData);
            videoCompletedRef.current = true;
        },
        [createEventData, pushToDataLayer]
    );

    const handleTimeUpdate = useCallback(
        (data, videoName, videoId) => {
            if (data.percent > 0.5 && !halfWatchedRef.current) {
                const eventData = createEventData(data, videoName, videoId);
                pushToDataLayer('video_half_watched', eventData);
                halfWatchedRef.current = true;
            }
        },
        [createEventData, pushToDataLayer]
    );

    const handleSeeked = useCallback(
        (data, videoName, videoId) => {
            if (!videoCompletedRef.current) {
                const eventData = createEventData(data, videoName, videoId);
                pushToDataLayer('video_seeked', eventData);
            }
        },
        [createEventData, pushToDataLayer]
    );

    useEffect(() => {
        if (!iframeRef.current) return;

        const player = new Player(iframeRef.current);
        playerRef.current = player;

        let videoName;
        let videoId;

        const initializePlayer = async () => {
            try {
                videoId = await player.getVideoId();
                videoName = await player.getVideoTitle();
                const eventData = createEventData({}, videoName, videoId);
                pushToDataLayer('detail:', eventData);

                player.on('play', data => handlePlay(data, videoName, videoId));
                player.on('pause', data => handlePause(data, videoName, videoId));
                player.on('ended', data => handleEnded(data, videoName, videoId));
                player.on('timeupdate', data => handleTimeUpdate(data, videoName, videoId));
                player.on('seeked', data => handleSeeked(data, videoName, videoId));
            } catch (error) {
                console.error('Error initializing player:', error);
            }
        };

        initializePlayer();

        return () => {
            player.off('play');
            player.off('pause');
            player.off('ended');
            player.off('timeupdate');
            player.off('seeked');
            player.destroy();
        };
    }, [
        vimeoSourceUrl,
        createEventData,
        handlePlay,
        handlePause,
        handleEnded,
        handleTimeUpdate,
        handleSeeked,
        pushToDataLayer,
        logicalName,
    ]);

    return (
        <div className="embed-responsive embed-responsive-16by9">
            <iframe
                allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                aria-label="Vimeo video player"
                className="embed-responsive-item"
                ref={iframeRef}
                src={vimeoSourceUrl}
                style={{ border: 0 }}
                video_name="Vimeo Video"
            />
        </div>
    );
};

export default VimeoPlayer;
