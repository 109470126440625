function getVimeoId(mediaSources) {
    if (mediaSources.length > 0) {
        const firstSource = mediaSources[0][Object.keys(mediaSources[0])[0]];
        return firstSource
            .split('https://player.vimeo.com/external/')
            .pop()
            .split('.')[0];
    }
    return null;
}

export default getVimeoId;
