import React, { useMemo } from 'react';
import ProLink from '../../ProLink/ProLink';
import classNames from 'classnames';

const Slim = ({ type, results = [], searchOnClick = type === 's', cat, selectedResultKey, supportLink }) => {
    const getUrlLink = result =>
        supportLink
            ? `/support-services/primesupport?q=${result.name}#search-results`
            : `/search?type=${type}&cat=${cat}&q=${result.name}`;

    const listItems = useMemo(
        () =>
            results.map((result, i) => {
                const isActive = selectedResultKey === i;
                const key = `item${i}`;

                if (typeof result === 'string' || result instanceof String) {
                    return (
                        <li key={key} className={classNames({ active: isActive })}>
                            <a href={getUrlLink(result)} data-result={result} className="slim-block-link">
                                <h5>{result}</h5>
                            </a>
                        </li>
                    );
                }

                return (
                    <li key={key} className={classNames({ active: isActive })}>
                        <ProLink to={getUrlLink(result)} className="slim-block-link">
                            <h5>{result.name}</h5>
                        </ProLink>
                    </li>
                );
            }),
        [results, selectedResultKey, searchOnClick, type, cat]
    );

    return <ul className="list-unstyled">{listItems}</ul>;
};

export default Slim;
