import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import MediaEmbed from './../../shared/MediaEmbed/MediaEmbed';
import './TextMediaVariable.scss';
import { useScreenSize } from '../../../helpers';
import { getComposedContent, getVimeoId } from './helpers';

const TextMediaVariable = ({ data = {} }) => {
    const { isMobileScreen: isMobile } = useScreenSize();
    const {
        logicalName,
        titleSize,
        isDecorativeTitle,
        mobileBackgroundColor,
        backgroundColor,
        mobileBackgroundImage,
        backgroundImage,
        title,
        textColor,
        removeBottomPadding,
        content,
        removeTopPadding,
        largeText,
        alignment,
        media = [],
    } = data;

    const inlineStyle = {
        backgroundColor: isMobile ? mobileBackgroundColor : backgroundColor,
        color: textColor,
        ...(isMobile && mobileBackgroundImage && { backgroundImage: `url('${mobileBackgroundImage}')` }),
        ...(!isMobile && backgroundImage && { backgroundImage: `url('${backgroundImage}')` }),
    };

    const headerStyle = {
        color: textColor,
    };

    const dynamicClasses = classNames({
        container: true,
        [alignment]: alignment,
    });

    const mediaPayload = media.length > 0 ? media[0] : {};

    const {
        type: mediaType,
        altText = '',
        src: mediaSrc,
        poster: mediaPoster,
        sources: mediaSources = [],
        youtubeSourceUrl,
        vimeoSourceUrl,
        ariaHidden,
    } = mediaPayload;

    const mediaEmbedProps = {
        mediaType,
        altText,
        mediaSrc,
        mediaPoster,
        mediaSources: mediaSources.length > 0 ? mediaSources[0] : {},
        vimeoId: getVimeoId(mediaSources),
        youtubeSourceUrl,
        vimeoSourceUrl,
        parentProps: { data },
        logicalName,
        ariaHidden,
    };

    const composedMedia = <MediaEmbed {...mediaEmbedProps} />;

    const HeaderElement = titleSize || 'h2';

    const titleMarkup = isDecorativeTitle ? (
        <p className={HeaderElement} style={headerStyle}>
            {title}
        </p>
    ) : (
        <HeaderElement style={headerStyle}>{title}</HeaderElement>
    );

    const contentMarkup = content && <div className="inner-content" dangerouslySetInnerHTML={{ __html: content }} />;

    const composedContent = getComposedContent({
        isMobile,
        alignment,
        titleMarkup: title ? titleMarkup : null,
        contentMarkup,
        composedMedia,
        dynamicClasses,
        title,
        content,
    });

    const wrapperClasses = classNames({
        'container-fluid': true,
        module: true,
        'module-large-text': largeText,
        'text-media-variable': true,
        'text-media-variable-has-image': data.media && data.media.length > 0,
        'remove-bottom-padding': removeBottomPadding,
        'remove-top-padding': removeTopPadding,
    });

    return (
        <div className={wrapperClasses} id={logicalName} style={inlineStyle}>
            {composedContent}
        </div>
    );
};

TextMediaVariable.propTypes = {
    data: PropTypes.shape({
        logicalName: PropTypes.string.isRequired,
        title: PropTypes.string,
        content: PropTypes.string,
    }),
};

export default TextMediaVariable;
